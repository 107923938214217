import React, { Fragment, useEffect } from "react";
import MetaTags from "react-meta-tags";

import Loading from "../blocks/loading/Loading";
import Header from "../blocks/header/Header";
import Footer from "../blocks/footer/Footer";

import PageTitleCommon from "../blocks/page-title/PageTitleCommon";
import WhyUsContent from "../blocks/why-us/WhyUsContent";

const WhyUs = () => {
  useEffect(() => {
    document.body.classList.add("page");
    document.body.classList.add("header-menu-true");

    return () => {
      document.body.classList.remove("page");
      document.body.classList.remove("header-menu-true");
    };
  }, []);

  return (
    <Fragment>
      <MetaTags>
        <meta charSet="UTF-8" />
        <title>
          BKCE International for logistics-transport-trading,Expertise
        </title>

        <meta httpEquiv="x-ua-compatible" content="ie=edge" />
        <meta name="viewport" content="width=device-width, initial-scale=1" />
        <meta
          name="description"
          content="BKCE is a one stop logistics solutions provider with diversified services. we believe in listening to our customers, understanding their specific requirements and delivering cost effective solutions, while allowing them to stay focused on their core business activities,Aircraft on ground, ship spares, project cargo,gaaz and oil trasnportation,exhibitions,trade facilitation and yacht luxury vehicles."
        />
        <meta
          name="keywords"
          content="transport , trading , DHL , FedEx,logistics"
        />
        <meta name="robots" content="index, follow, noodp" />
        <meta name="googlebot" content="index, follow" />
        <meta name="google" content="notranslate" />
        <meta name="format-detection" content="telephone=no" />
      </MetaTags>

      <Loading />

      <Header logoColor="dark" />

      <main id="main" className="site-main">
        <PageTitleCommon
          title="Expertise"
          image={`url( ${require("../assets/img/placeholder/exper.webp")} )`}
        />

        <section id="page-content" className="block spacer p-top-xl">
          <WhyUsContent />
        </section>
      </main>

      <Footer />
    </Fragment>
  );
};

export default WhyUs;
