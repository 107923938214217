import React, { Fragment, useEffect } from "react";
import MetaTags from "react-meta-tags";

import Loading from "../blocks/loading/Loading";
import Header from "../blocks/header/Header";
import Footer from "../blocks/footer/Footer";

import PageTitleCommon from "../blocks/page-title/PageTitleCommon";
import ServicesContent from "../blocks/services/ServicesContent";
import ClientsList from "../blocks/clients/ClientsList";

const Services = () => {
  useEffect(() => {
    document.body.classList.add("archive");
    document.body.classList.add("header-menu-true");

    return () => {
      document.body.classList.remove("archive");
      document.body.classList.remove("header-menu-true");
    };
  }, []);
  return (
    <Fragment>
      <MetaTags>
        <meta charSet="UTF-8" />
        <title>
          BKCE International for logistics-transport-trading,Services
        </title>

        <meta httpEquiv="x-ua-compatible" content="ie=edge" />
        <meta name="viewport" content="width=device-width, initial-scale=1" />
        <meta
          name="description"
          content="Air freight ,ocean Freight ,project cargo,Multi Modal Transport ,warehousing and customs brokrage : we create solutions adapted to all your international transport needs, and bring our Customs & VAT expertise."
        />
        <meta
          name="keywords"
          content="transport , trading , DHL , FedEx,logistics"
        />
        <meta name="robots" content="index, follow, noodp" />
        <meta name="googlebot" content="index, follow" />
        <meta name="google" content="notranslate" />
        <meta name="format-detection" content="telephone=no" />
      </MetaTags>

      <Loading />

      <Header logoColor="dark" />

      <main id="main" className="site-main">
        <PageTitleCommon
          title="Services"
          image={`url( ${require("../assets/img/placeholder/servi.jpg")} )`}
        />

        <section id="page-content" className="spacer p-top-xl">
          <div className="wrapper">
            <ServicesContent />
          </div>
        </section>
        <section id="clients" className="block spacer p-top-xl">
          <ClientsList />
        </section>
      </main>

      <Footer />
    </Fragment>
  );
};

export default Services;
