import React, { Component } from "react";

class PageTitleCommon extends Component {
  constructor() {
    super();
    this.state = {
      lang: "En",
    };

    this.onChangeValue = this.onChangeValue.bind(this);
  }

  onChangeValue(event) {
    this.setState({
      lang: event.target.title,
    });
  }

  render() {
    console.log(this.props.image);
    return (
      <section
        id="page-title"
        className="block"
        style={{
          backgroundImage: this.props.image,
        }}
      >
        <div className="wrapper h-100">
          <div className="d-flex justify-content-between position-relative h-100 conta">
            <div className="align-self-center centered">
              <div className="title ">
                <h1>{this.props.title}</h1>
              </div>
            </div>

            {/* <div className="lang-position">
              <nav className="languages">
                <ul className="nav" onClick={this.onChangeValue}>
                  <LanguageSelector
                    title="En"
                    className={this.state.lang === "En" ? "current-lang" : ""}
                  />
                  <LanguageSelector
                    title="Ru"
                    className={this.state.lang === "Ru" ? "current-lang" : ""}
                  />
                  <LanguageSelector
                    title="Lt"
                    className={this.state.lang === "Lt" ? "current-lang" : ""}
                  />
                </ul>
              </nav>
            </div> */}
          </div>
        </div>

        <div className="page-title-bg-color"></div>
      </section>
    );
  }
}

export default PageTitleCommon;
