import React from "react";
import global from "../../assets/img/placeholder/global.png";
const Global = () => {
  return (
    <div className="client-items clearfix align-center global">
      <div className="list-group list-group-horizontal spacer m-top-lg m-bottom-lg-media-xl style-default percentage">
        <div className="list-group-item">
          <h4>10</h4>
          <p>Years in Business</p>
        </div>

        <div className="list-group-item">
          <h4>50%</h4>
          <p>Air Freight</p>
        </div>
        <div className="list-group-item">
          <h4>20%</h4>
          <p>
            Land & <br />
            Freight
          </p>
        </div>
      </div>
      <img src={global} alt="global net work" width="60%" />
      <div className="list-group list-group-horizontal spacer m-top-lg m-bottom-lg-media-xl style-default percentage">
        <div className="list-group-item">
          <h4>30%</h4>
          <p>Ocean Freight</p>
        </div>
        <div className="list-group-item">
          <h4>120</h4>
          <p>Countries</p>
        </div>
        <div className="list-group-item">
          <h4>300</h4>
          <p>Global Partners</p>
        </div>
      </div>
    </div>
  );
};

export default Global;
