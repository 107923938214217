import React, { Fragment, useEffect } from "react";
import MetaTags from "react-meta-tags";

import Loading from "../blocks/loading/Loading";
import Header from "../blocks/header/Header";
import Footer from "../blocks/footer/Footer";

import PageTitleCommon from "../blocks/page-title/PageTitleCommon";

import Global from "../blocks/Global/Global";

const News = () => {
  useEffect(() => {
    document.body.classList.add("blog");
    document.body.classList.add("header-menu-true");

    return () => {
      document.body.classList.remove("blog");
      document.body.classList.remove("header-menu-true");
    };
  }, []);

  return (
    <Fragment>
      <MetaTags>
        <meta charSet="UTF-8" />
        <title>BKCE International</title>

        <meta httpEquiv="x-ua-compatible" content="ie=edge" />
        <meta name="viewport" content="width=device-width, initial-scale=1" />
        <meta name="description" content="News" />
        <meta
          name="keywords"
          content="transport , trading , DHL , FedEx, logistics"
        />
        <meta name="robots" content="index, follow, noodp" />
        <meta name="googlebot" content="index, follow" />
        <meta name="google" content="notranslate" />
        <meta name="format-detection" content="telephone=no" />
      </MetaTags>

      <Loading />

      <Header logoColor="dark" />

      <main id="main" className="site-main">
        <PageTitleCommon title="Global Network" />

        {/* <section id="page-content" className="block spacer p-top-xl">
                    <div className="wrapper">
                        <div className="content">
                            <div id="blog">
                                <div className="row gutter-width-md style-default">
                                    <NewsBlog />

                                    <div className="col-xl-4 col-lg-4 col-md-12">
                                        <aside id="aside" className="widget-area">
                                            <Widget />
                                        </aside>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section> */}

        <div className="client-items clearfix align-center block spacer p-top-xl">
          <Global />
        </div>
      </main>

      <Footer />
    </Fragment>
  );
};

export default News;
